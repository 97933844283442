import { request, noTimeOutReq } from "@/utils/request.js";

// 保存【盘点结果】
export function saveCheckResultApi(data) {
  return request({
    url: `/eqpt/stock/inventory/task/add`,
    method: "post",
    data,
  });
}

// 分页展示学校所有【盘点清单台账数据】
export function getSchoolCheckAccountListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/inventory/task/all/detail/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 提交【盘点任务】
export function submitCheckTaskApi(taskId) {
  return request({
    url: `/eqpt/stock/inventory/task/commit`,
    method: "post",
    params: {
      taskId,
    },
  });
}

// 展示【某个盘点任务单分页清单台账数据】
export function getTaskCheckAccountListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/inventory/task/detail/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data
  });
}

// 获取盘点位置
export function getCheckAddressApi(taskId) {
  return request({
    url: `/eqpt/stock/inventory/task/get/room/list`,
    method: "post",
    params: {
      taskId,
    },
  });
}

// 分页展示所有【盘点任务单】
export function getCheckTaskListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/inventory/task/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 展示某个实验室下所有【盘点清单数据】
export function getLabRoomCheckListApi(data) {
  return request({
    url: `/eqpt/stock/inventory/task/room/detail/list`,
    method: "post",
    data,
  });
}
