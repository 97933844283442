<template>
  <div>
    <div
      style="
        padding-left: 20px;
        color: rgb(78, 113, 239);
        font-size: 20px;
        margin-top: -10px;
        margin-bottom: 20px;
        margin-left: 40px;
        border-left: 4px solid rgb(78, 113, 239);
      "
    >
      {{ stockInfo.materialName }}
    </div>
    <!-- 信息详情内容 -->
    <div style="width: 800px; height: 550px; margin: 0 auto; display: flex">
      <div style="width: 50%">
        <div style="display: flex; background-color: rgb(250, 250, 250)">
          <div class="materialDetailItem itemFontWeight">所属单位</div>
          <div class="materialDetailItem">
            {{ stockInfo.affiliatedUnit ? stockInfo.affiliatedUnit : "/" }}
          </div>
        </div>
        <div style="display: flex">
          <div class="materialDetailItem itemFontWeight">国标编号</div>
          <div class="materialDetailItem">{{ stockInfo.internationCode }}</div>
        </div>
        <div style="display: flex; background-color: rgb(250, 250, 250)">
          <div class="materialDetailItem itemFontWeight">是否危化品</div>
          <div class="materialDetailItem">
            {{ stockInfo.materialHasDanger }}
          </div>
        </div>
        <div style="display: flex">
          <div class="materialDetailItem itemFontWeight">是否消耗品</div>
          <div class="materialDetailItem">
            {{ stockInfo.materialHasConsumables }}
          </div>
        </div>
        <div style="display: flex; background-color: rgb(250, 250, 250)">
          <div class="materialDetailItem itemFontWeight">规格型号功能</div>
          <div class="materialDetailItem">
            {{ stockInfo.materialSpecifications }}
          </div>
        </div>
        <!-- <div style="display: flex">
          <div class="materialDetailItem itemFontWeight">科目</div>
          <div class="materialDetailItem">{{ stockInfo.subjectDicId }}</div>
        </div> -->
        <div style="display: flex">
          <div class="materialDetailItem itemFontWeight">参考单价</div>
          <div class="materialDetailItem">
            {{ stockInfo.materialUnitPrice }}
          </div>
        </div>
        <div style="display: flex; background-color: rgb(250, 250, 250)">
          <div class="materialDetailItem itemFontWeight">配备要求</div>
          <div class="materialDetailItem">{{ stockInfo.equipRequire }}</div>
        </div>
      </div>
      <div style="width: 50%">
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            justify-content: space-around;
          "
        >
          <div
            style="
              text-align: center;
              width: 44%;
              height: 142px;
              line-height: 142px;
              border: 1px dashed #eee;
            "
          >
            <el-image v-if="imgUrl[0]" style="width: 100%; height: 142px" :src="imgUrl[0]" fit="cover" />
            <span v-else>暂无图片</span>
          </div>
          <div
            style="
              text-align: center;
              width: 44%;
              height: 142px;
              line-height: 142px;
              border: 1px dashed #eee;
            "
          >
            二维码
          </div>
        </div>
        <div style="display: flex; background-color: rgb(250, 250, 250)">
          <div class="materialDetailItem itemFontWeight">保管人</div>
          <div class="materialDetailItem">
            {{ stockInfo.keeper ? stockInfo.keeper : "/" }}
          </div>
        </div>
        <!-- <div style="display: flex">
          <div class="materialDetailItem itemFontWeight">存放位置</div>
          <div class="materialDetailItem">
            {{ stockInfo.sysOrgSchoolRoomName }}
          </div>
        </div>
        <div style="display: flex; background-color: rgb(250, 250, 250)">
          <div class="materialDetailItem itemFontWeight">SN码</div>
          <div class="materialDetailItem">
            {{ stockInfo.snCode ? stockInfo.snCode : "/" }}
          </div>
        </div> -->
        <div style="display: flex">
          <div
            style="
              width: 50%;
              height: 120px;
              line-height: 120px;
              text-align: center;
              font-weight: 700;
            "
          >
            物品特性
          </div>
          <div
            style="
              width: 50%;
              height: 120px;
              line-height: 120px;
              text-align: center;
            "
          >
            {{ handleFormateFeature(stockInfo.materialFeature) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkMaterialCom",
  props: {
    stockInfo: {
      require: true,
      type: Object,
    },
    imgUrl: {
      type: Array,
    },
  },
  // computed: {
    // picUrl(){
    //   return this.$imageAddress(this.imgUrl[0])
    // }
  // },
  methods: {
    handleFormateFeature(list) {
      if (!list || !list.length) return "/";
      let result = "";
      list.forEach((item, i) => {
        if (i === 0) {
          result += item.itemTagName;
        } else {
          result += "，" + item.itemTagName;
        }
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.materialDetailItem {
  width: 50%;
  padding: 20px 0;
  text-align: center;
  &.itemFontWeight {
    font-weight: 700;
  }
}
</style>
