<template>
  <div>
    <el-row>
      <el-button icon="el-icon-download">导出</el-button>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="checkAccountCondition">
        <el-form-item label="任务编号/名称" class="formItemBoxStyle">
          <el-input
            style="width: 200px"
            v-model="checkAccountCondition.taskCodeName"
            placeholder="请输入"
            @blur="handleQueryTableData"
          />
        </el-form-item>
        <el-form-item label="存放位置" class="formItemBoxStyle">
          <el-select
            style="width: 200px"
            v-model="checkAccountCondition.sysOrgSchoolRoomName"
            placeholder="请选择"
            @change="handleQueryTableData"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item, i) in functionRoomDataList"
              :key="i"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            style="width: 200px"
            v-model="checkAccountCondition.inventoryStatus"
            placeholder="请选择"
            @change="handleQueryTableData"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="盘盈" :value="2"></el-option>
            <el-option label="盘亏" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物品编号/名称" class="formItemBoxStyle">
          <el-input
            style="width: 200px"
            v-model="checkAccountCondition.materialCodeName"
            placeholder="请输入"
            @blur="handleQueryTableData"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetTableData">重置</el-button>
          <el-button type="primary" @click="handleQueryTableData"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        border
        :data="checkAccountData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="任务编号" prop="taskCode"></el-table-column>
        <el-table-column label="任务名称" prop="taskName" show-overflow-tooltip></el-table-column>
        <el-table-column
          label="存放位置"
          prop="sysOrgSchoolRoomName"
        ></el-table-column>
        <el-table-column
          label="国标编号"
          prop="internationCode"
          width="80"
        ></el-table-column>
        <el-table-column label="名称" prop="materialName" width="120">
          <template slot-scope="scoped">
            <span
              @click="handleCheckMaterialStockInfo(scoped.row)"
              style="cursor: pointer; color: #00f; text-decoration: underline"
              >{{ scoped.row.materialName }}</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column label="所属科目" prop="subjectName"></el-table-column> -->
        <el-table-column
          label="规格型号功能"
          prop="materialSpecifications"
        ></el-table-column>
        <el-table-column
          label="单位"
          prop="materialUnit"
          width="80"
        ></el-table-column>
        <el-table-column
          label="账面库存"
          prop="stockNum"
          width="80"
        ></el-table-column>
        <el-table-column
          label="实际库存"
          prop="actualNum"
          width="80"
        ></el-table-column>
        <el-table-column
          label="状态"
          width="80"
          prop="inventoryStatus"
          :formatter="customFormatter"
        >
        </el-table-column>
        <el-table-column
          label="具体原因"
          prop="inventoryReason"
        ></el-table-column>
        <el-table-column
          label="盘点时间"
          :formatter="customFormatter"
          prop="taskSubmitTime"
          sortable
        >
          <template>
            <!-- {{ handleDateFormate(scope.row.time) }} -->
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeCheckTableMsg"
        @current-change="handleCurrentChangeCheckTableMsg"
        :current-page="checkPageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 150, 200]"
        :page-size="checkPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="checkPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 查看实验设备详情弹框 -->
    <el-dialog
      title="查看实验设备详情"
      :visible.sync="checkMaterialStockInfoDialogVisible"
    >
      <checkMaterialCom
        :stockInfo="materialStockInfo"
        :imgUrl="editDialogImageList"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  getTaskCheckAccountListApi,
  getSchoolCheckAccountListApi,
} from "@/api/materialManage/checkRecord.js";
import { getStockEquipmentDetailApi } from "@/api/materialManage/materialAccount.js";
import checkMaterialCom from "../components/checkMaterialCom.vue";
import { getStore } from "@/utils/token.js";
import { getSchoolRoomPageListApi } from "@/api/mechanism/schoolRoomManage.js";
import { isEmpty, formatDate } from "@/utils/util";
export default {
  components: {
    checkMaterialCom,
  },
  data() {
    return {
      checkAccountCondition: {
        taskCodeName: "",
        inventoryStatus: 0,
        sysOrgSchoolRoomName: "",
        materialCodeName: "",
        sysOrgSchoolId: 0,
      },
      checkAccountData: [],
      checkPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 2,
      },
      materialStockInfo: {
        affiliatedUnit: "",
        internationCode: "",
        materialHasDanger: "",
        materialHasConsumables: "",
        materialSpecifications: "",
        subjectDicId: "",
        materialUnitPrice: "",
        equipRequir: "",
        keeper: "",
        placeRoomId: "",
        snCode: "",
        materialFeature: "", // 物品特征
      },
      editDialogImageList: [],
      checkMaterialStockInfoDialogVisible: false,

      functionRoomDataList: [],
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"));
    this.checkAccountCondition.sysOrgSchoolId = this.sysOrgSchoolId;
    this.fetchCheckRecordAccountData();
    this.fetchFunctionRoomTableData();
  },
  methods: {
    fetchCheckRecordAccountData() {
      getSchoolCheckAccountListApi(
        this.checkPageInfo.pageIndex,
        this.checkPageInfo.pageSize,
        this.checkAccountCondition
      ).then((res) => {
        if (res.success) {
          this.checkAccountData = res.data;
          this.checkPageInfo.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取添加功能室表格数据
    fetchFunctionRoomTableData() {
      getSchoolRoomPageListApi(1, 10000, {
        sysOrgSchoolId: this.sysOrgSchoolId,
      }).then((res) => {
        if (res.success) {
          this.functionRoomDataList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleResetTableData() {
      this.checkAccountCondition = {
        taskCodeName: "",
        inventoryStatus: 0,
        sysOrgSchoolRoomName: "",
        materialCodeName: "",
        sysOrgSchoolId: this.sysOrgSchoolId,
      };
      this.handleQueryTableData();
    },
    handleQueryTableData() {
      this.checkPageInfo.pageIndex = 1;
      this.checkPageInfo.pageSize = 10;
      this.fetchCheckRecordAccountData();
    },
    handleSizeChangeCheckTableMsg(val) {
      this.checkPageInfo.pageIndex = 1;
      this.checkPageInfo.pageSize = val;
      this.fetchCheckRecordAccountData();
    },
    handleCurrentChangeCheckTableMsg(val) {
      this.checkPageInfo.pageIndex = val;
      this.fetchCheckRecordAccountData();
    },
    // 日期转换
    handleDateFormate(timestamp) {
      let time = new Date(timestamp);
      return formatDate(time, "yyyy/MM/dd");
    },
    // 查看耗材详情
    handleCheckMaterialStockInfo(row) {
      this.editDialogImageList = [];
      let fd = new FormData();
      fd.append("sysExpConsumeMaterialId", row.sysExpConsumeMaterialId);
      fd.append("sysOrgSchoolId", this.sysOrgSchoolId);
      // fd.append("roomId", row.roomId ? row.roomId : 0);
      getStockEquipmentDetailApi(fd).then((res) => {
        if (res.success) {
          this.editDialogImageList = [];
          const { data } = res;
          this.materialStockInfo = {
            materialName: data.materialName,
            affiliatedUnit: data.tenantId,
            internationCode: data.internationCode,
            materialHasDanger: data.materialHasDanger ? "是" : "否",
            materialHasConsumables: data.materialHasConsumables ? "是" : "否",
            materialSpecifications: data.materialSpecifications,
            subjectDicId: data.subjectTypeDicName,
            materialUnitPrice: data.materialUnitPrice,
            equipRequire: data.equipRequire == 1 ? "基本" : "选配",
            keeper: data.keeper,
            placeRoomId: data.roomName,
            snCode: data.snCode,
            materialFeature: data.materialItemTagDTOS, // 物品特征
          };
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          this.checkMaterialStockInfoDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "inventoryStatus":
          switch (row.inventoryStatus) {
            case 1:
              return "正常";
            case 2:
              return "盘盈";
            case 3:
              return "盘亏";
          }
        case "taskSubmitTime":
          if (isEmpty(row.taskSubmitTime)) {
            return "/";
          }
          return formatDate(new Date(row.taskSubmitTime), "yyyy/MM/dd");
      }
    },
  },
};
</script>

<style></style>
